<template>
  <v-card class="mt-4 px-4">
    <v-card-text class="">
      <v-switch
        v-model="b_StatusImpersonation"
        color="primary"
        value
        :input-value="getImpersonation.b_Status"
        :disabled="!getImpersonation.b_Status"
        inset
        :label="s_LabelSwitch"
      ></v-switch>
      {{ s_LabelChip }}
      <v-chip
        class="ma-2"
        color="orange"
      >
        {{ getImpersonation.s_FullName === null ? $t('none') : getImpersonation.s_FullName }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>
<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Pensum',
    },
    data () {
      return {
        b_StatusImpersonation: false
      }
    },
    watch: {
      b_StatusImpersonation (val) {
        if (val === false) this.unsetImpersonation()
      }
    },
    computed: {
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
      s_LabelSwitch () {
        if (this.$route.meta.role === config.roles[4]) return this.$t('Surf as a teacher')
        if (this.$route.meta.role === config.roles[1]) return this.$t('Surf as a student')
      },
      s_LabelChip () {
        if (this.$route.meta.role === config.roles[4]) return this.$t('general.teacher')
        if (this.$route.meta.role === config.roles[1]) return this.$t('general.student')
      }
    },
    methods: {
      ...mapActions(['setImpersonation']),
      unsetImpersonation() {
        this.setImpersonation({
          b_Status: false,
          i_AddressRoleID: null,
          s_FullName: null,
          s_Role: null,
          a_PlanningPeriods: []
        })
        this.b_StatusImpersonation = false
      }
    },
    beforeMount () {
      this.b_StatusImpersonation = this.getImpersonation.b_Status
    },
  }
</script>
